h2 {
  /*Sender, Details*/
  font-size: 25px;
  font-weight: bold;
}

.pinbutton {
  width: 343px;
  height: 60px;
  border-radius: 5px;
  border: solid 1px #979797;
  background-color: #fafffa;
  color: #175332;
  display: inline;
  font-size: 25px;
  float: right;
  margin: center;
  font-family: OpenSans-Regular;
}

table {
  width: 90%;
  height: 60px;
  border-radius: 5px;
  border: solid 1px #979797;
  background-color: #ffffff;
}
td {
  /* checkbox */
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: solid 1px #767676;
}
tr {
  /* summary and checkbox */
  width: 70%;
  height: 27px;
  font-family: OpenSans;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #525252;
}

.container {
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: solid 1px #979797;
  background-color: #ffffff;
  margin-left: 0%;
  font-size: 25px;
}

/*check boxes checked/unchecked color change*/
input[type='checkbox'] {
  position: relative;
  cursor: pointer;
}
input[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 1px solid #555555;
  border-radius: 3px;
  background-color: white;
}
input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
details {
  width: 90%;
}

.react-sweet-progress-symbol {
  display: none !important;
}
