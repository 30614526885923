input.middle:focus {
  outline-width: 0;
}

.navLink {
  color: #fff;
}

.navLink:hover {
  color: #fff;
  text-decoration: none;
  transition-duration: 0.5s;
}

:focus {
  outline: none !important;
}

html,
body {
  margin: 0;
}

#Sidebar {
  font-family: sans-serif;

  /* Give app full page to work with */
  height: 100vh;
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

.menu {
  color: #0052a5;
}

.bm-item {
  text-decoration: none;
  color: #fafafa;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #fafafa;
  text-decoration: none;
  transition: color 0.5s;
}

.border-item {
  border-bottom: 1px solid rgba(36, 36, 36, 0.25);
  width: 100%;
}

.edge-item {
  border-top: 1px solid rgba(36, 36, 36, 0.25);
}

.current-user {
  width: 100%;
  color: #eaeaea;
  font-size: 0.8rem;
}

.current-user:hover {
  color: #eaeaea;
  background-color: #0052a5;
}

.current-user p {
  margin: 0;
}

.current-user span {
  color: #bfdfff;
}

.topLogo {
  background-color: #00478e;
  width: 100%;
  height: 3.55rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.log-out {
  width: 100%;
  position: absolute;
  bottom: 0.5rem;
  padding: 1rem;
}
