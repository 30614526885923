body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-gallery.fullscreen-modal {
  z-index: 10000 !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  transform: scale(0.6);
}

.image-gallery-svg {
  transform: scale(0.7) !important;
}

.image-gallery-image {
  height: 275px !important;
}

.image-gallery-thumbnails-container {
  overflow: auto !important;
  padding: 10px 0;
}

.image-gallery.fullscreen-modal .image-gallery-content.fullscreen .image-gallery-slide img.image-gallery-image {
  height: 100% !important;
  object-fit: scale-down;
}

.image-gallery-content .image-gallery-slide img.image-gallery-image {
  max-height: calc(100vh - 200px);
}

.react-pdf__Page__canvas {
  padding-bottom: 50px;
  margin: 0 auto;
  width: 300px !important;
  height: 100% !important;
}

.pdf-gallery-full-screen {
  width: 400px !important;
}
